import React from 'react';
import style from './Login.module.css';
import logoImg from '../../images/Login/logo.png';
import { saveLoginInfo, getLoginInfo } from '../../UserStore';
import { ApiPost, UserLoginSession_loginByMobilePassword } from '../../utils/Api';
import toast from '../../components/Toast';
import { withRouter } from 'react-router-dom';

class Login extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     mobile: '12355566677',
        //     password: '987654321',
        //     logining: false
        // }
        this.state = {
            mobile: '',
            password: '',
            logining: false
        }
    }
    componentDidMount() {
        document.title = '登录';
        if (getLoginInfo()) this.props.history.replace('/home');
    }
    mobileOnChange = e => {
        this.setState({ mobile: e.target.value });
    };
    passwordOnChange = e => {
        this.setState({ password: e.target.value });
    };
    loginClick = async () => {
        const { mobile, password } = this.state;
        if (mobile === '') return toast('请输入手机号');
        if (password === '') return toast('请输入密码');
        this.setState({ logining: true });
        try {
            const device_token = window.btoa(navigator.userAgent).substr(0, 16);
            const res = await ApiPost(UserLoginSession_loginByMobilePassword, {  mobile, country_no: '86', password, device_token, device_type: 'h5'});
            saveLoginInfo(res);
            toast('登录成功~', 500);
            setTimeout(() => this.props.history.replace('/home'), 500);
        } catch(e) {
            toast(e);
            this.setState({ logining: false });
        }
    };
    render() {
        const { mobile, password, logining } = this.state;
        return (
            <div className={style['login-wrap']}>
                <div className={style['logo']}><img src={logoImg} /></div>
                <div className={style['form-input']}>
                    <input placeholder="请输入手机号" maxLength="11" value={mobile} onChange={this.mobileOnChange} />
                </div>
                <div className={style['form-input']}>
                    <input type="password" placeholder="请输入密码" value={password} onChange={this.passwordOnChange} />
                </div>
                { logining ? <div className={style['form-submit']}>登录中...</div> : <div className={style['form-submit']} onClick={this.loginClick}>快速登录</div> }
            </div>
        );
    }
}

export default withRouter(Login);