import React from 'react';
import style from '../Pay/Pay.module.css';
import qrcode from '../../../images/Order/qrcode.png';
import sucImg from '../../../images/Order/success.png';
import { withRouter } from 'react-router-dom';


class PaySuccess extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        document.title = '订单支付';
    }
    render() {
        return (
            <div className={style['success-wrap']}>
                <img className={style['suc-img']} src={sucImg} />
                <div className={style['tip1']}>订货成功等待厂家发货</div>
                <div className={style['oper-btn']} onClick={() => {
                    this.props.history.replace('/my/order?t=待发货');
                }}>查看订单</div>
            </div>
        );
    }
}

export default withRouter(PaySuccess);