import _ from 'lodash';
import toast from './components/Toast';

const add2cart = ware => {
    let cartList = getCartList();
    const i = _.findIndex(cartList, { selectedSkuId: ware.selectedSkuId });
    if (i < 0) {
        ware.cart_num = 1;
        cartList.push(ware);
        saveCart(cartList);
    } else {
        cartList[i].cart_num ++;
        saveCart(cartList);
    }
};

const remove2cart = ware => {
    let cartList = getCartList();
    _.remove(cartList, { selectedSkuId: ware.selectedSkuId });
    saveCart(cartList);
};

const adjustCount = (ware, count) => {
    if (count <= 0) {
        remove2cart(ware);
    } else {
        let cartList = getCartList();
        const i = _.findIndex(cartList, { id: ware.id });
        if (i >= 0) {
            cartList[i].cart_num = count;
            saveCart(cartList);
        }
    }
};

const plusCartCount = ware => {
    let cartList = getCartList();
    const i = _.findIndex(cartList, { selectedSkuId: ware.selectedSkuId });
    if (i < 0) return;
    if (cartList[i].cart_num + 1 > cartList[i].stock) {
        return toast('超过库存');
    }
    cartList[i].cart_num ++;
    saveCart(cartList);
}

const minusCartCount = ware => {
    let cartList = getCartList();
    const i = _.findIndex(cartList, { selectedSkuId: ware.selectedSkuId });
    if (i < 0) return;
    if (cartList[i].cart_num - 1 <= 0) return remove2cart(ware);
    cartList[i].cart_num --;
    saveCart(cartList);
}

const clearCart = () => {
    localStorage.removeItem('cart');
};

const saveCart = cartList => {
    localStorage.setItem('cart', JSON.stringify(cartList));
}

const getCartList = () => {
    let cart = localStorage.getItem('cart');
    if (cart === null) return [];
    cart = JSON.parse(cart);
    return cart || [];
};

const calcCartAmount = () => {
    const cartList = getCartList();
    let amount = 0;
     cartList.forEach(val => {
        amount += val.cart_num * val.selectedSku.price
    })
    return amount;
};

export {
    add2cart,
    remove2cart,
    adjustCount,
    clearCart,
    getCartList,
    minusCartCount,
    plusCartCount,
    calcCartAmount
}