import React from 'react';
import {
    Link, withRouter
} from "react-router-dom";
import Footer from '../../../components/Footer';
import style from './My.module.css';
import c1 from '../../../images/My/c1.png';
import c2 from '../../../images/My/c2.png';
import c3 from '../../../images/My/c3.png';
import c4 from '../../../images/My/c4.png';
import c5 from '../../../images/My/c5.png';
import { getLoginInfo, removeLoginInfo } from '../../../UserStore';

class My extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginInfo: {
                nickname: '',
                avatar: '',
                account_type: ''
            }
        }
    }
    componentDidMount() {
        document.title = '我的';
        const loginInfo = getLoginInfo();
        if (loginInfo === false) return this.props.history.replace('/');
        this.setState({
            loginInfo: getLoginInfo()
        });
    }
    logout = () => {
        removeLoginInfo();
        this.props.history.replace('/');
    }
    render() {
        const { loginInfo } = this.state;
        const accountType = { franchise: '加盟账户', direct: '直营账户' };
        return (
            <div className={style['my-wrap']}>
                <div className={style['my-inner']}>
                    <div className={style['top-wrap']}>
                        <div className={style['head']}>
                            <img src={loginInfo.avatar} />
                        </div>
                        <div className={style['nickname']}>{loginInfo.nickname}</div>
                        <div className={style['usertype']}>{accountType[loginInfo.account_type] || '-'}</div>
                    </div>
                    <div className={style['nav-wrap']}>
                        <Link to="/my/order?t=待付款" className={style['nav-item']}>
                            <div className={style['icon']}><img src={c1} /></div>
                            <div className={style['title']}>待付款</div>
                        </Link>

                        <Link to="/my/order?t=待发货" className={style['nav-item']}>
                            <div className={style['icon']}><img src={c2} /></div>
                            <div className={style['title']}>待发货</div>
                        </Link>
                        <Link to="/my/order?t=待收货" className={style['nav-item']}>
                            <div className={style['icon']}><img src={c3} /></div>
                            <div className={style['title']}>待收货</div>
                        </Link>
                        <Link to="/my/order?t=已完成" className={style['nav-item']}>
                            <div className={style['icon']}><img src={c4} /></div>
                            <div className={style['title']}>已完成</div>
                        </Link>
                        <Link to="/my/order?t=全部订单" className={style['nav-item']}>
                            <div className={style['icon']}><img src={c5} /></div>
                            <div className={style['title']}>全部订单</div>
                        </Link>
                    </div>
                    <div onClick={this.logout} className={style['logout']}>
                        <span>退出登录</span>
                    </div>
                </div>
                <Footer index="my" />
            </div>
        );
    }
}

export default withRouter(My);