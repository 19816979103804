import React from 'react';
import style from './Cart.module.css';
import Footer from '../../components/Footer';
import { getCartList, minusCartCount, plusCartCount } from '../../CartStore';
import { formatMoney } from '../../utils/Tools';
import { getLoginInfo } from '../../UserStore';
import OrderCart from '../../mobx/OrderCart';
import { withRouter } from 'react-router-dom';

function NumberBtn(props) {
    return (
        <div className={style['number-btn']}>
            <div className={style['btn']} onClick={props.minus}>-</div>
            <span>{props.number}</span>
            <div className={style['btn']} onClick={props.plus}>+</div>
        </div>
    );
}

class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartList: getCartList(),
            selectedItems: []
        }
    }
    componentDidMount() {
        document.title = '购物车';
        const loginInfo = getLoginInfo();
        if (!loginInfo) {
            return this.props.history.replace('/');
        }
    }
    cartMinus = ware => {
        minusCartCount(ware);
        this.setState({
            cartList: getCartList()
        });
    };
    cartPlus = ware => {
        plusCartCount(ware);
        this.setState({
            cartList: getCartList()
        });
    };
    toggleChecked = id => {
        const { selectedItems } = this.state;
        let newItems = [];
        if (selectedItems.indexOf(id) >= 0) {
            newItems = selectedItems.filter(val => val != id);
        } else {
            newItems = [...selectedItems, id];
        }
        this.setState({
            selectedItems: newItems
        });
    };
    isCheckedAll = () => {
        const { cartList, selectedItems } = this.state;
        let checkedAll = true;
        cartList.forEach(val => {
            if (selectedItems.indexOf(val.id) == -1) checkedAll = false;
        });
        return checkedAll;
    }
    toggleCheckAll = () => {
        if (this.isCheckedAll()) {
            this.setState({ selectedItems: [] });
        } else {
            const selectedItems = this.state.cartList.map(val => val.id);
            this.setState({
                selectedItems
            });
        }
    }
    goOrder = e => {
        if (this.state.selectedItems.length === 0) return;
        const items = this.state.cartList.filter(val => {
            return this.state.selectedItems.indexOf(val.id) >= 0
        })
        OrderCart.set(items);
        this.props.history.push('/order/confirm');
    };
    render() {
        const { cartList, selectedItems } = this.state;
        const checkedAll = this.isCheckedAll();
        let totalPrice = 0;

        const cartListItems = cartList.map(val => {
            const checked = selectedItems.indexOf(val.id) >= 0;
            if (checked) totalPrice += val.selectedSku.price * val.cart_num;
            return (
                <div className={style['list-item']} key={val.selectedSkuId}>
                    <div className={checked ? style['checked'] : style['check']} onClick={() => this.toggleChecked(val.id)}></div>
                    <div className={style['pic']}><img src={val.small_image} /></div>
                    <div className={style['info']}>
                        <div className={style['title']}>{val.title}</div>
                        <div className={style['sku-title']}>{val.selectedSku.title}</div>
                        <div className={style['price']}>￥{formatMoney(val.selectedSku.price)}<span>/{val.unit}</span></div>
                    </div>
                    <div className={style['oper']}>
                        <NumberBtn number={val.cart_num} plus={() => this.cartPlus(val)} minus={() => this.cartMinus(val)} />
                    </div>
                </div>
            );
        });
        return (
            <div className={style['cart-wrap']}>
                <div className={style['cart-wrap-inner']}>
                    <div className={style['cart-content']}>
                        <div className={style['list-inner']}>
                            {cartListItems}
                        </div>
                    </div>
                    <div className={style['oper-wrap']}>
                        <div className={style['left']} onClick={this.toggleCheckAll}>
                            <div className={checkedAll ? style['checked'] : style['check']}></div>
                            <div className={style['all']}>全选</div>
                        </div>
                        <div className={style['sum']}>
                            <div className={style['total']}>
                                合计：<span>￥{formatMoney(totalPrice)}</span>
                            </div>
                            <div className={style['order-btn']} onClick={this.goOrder}>去结算({selectedItems.length})</div>
                        </div>
                    </div>
                </div>
                <Footer index="cart" />
            </div>
        );
    }
}

export default withRouter(Cart);