import React from 'react';
import style from './Pay.module.css';
import qrcode from '../../../images/Order/qrcode.png';
import sucImg from '../../../images/Order/success.png';
import toast from '../../../components/Toast';
import { ApiPost, ApiUpload, DtOrder_uploadPayEvidence } from '../../../utils/Api';
import { withRouter } from 'react-router-dom';


class Pay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            file: false,
            order_id: '',
            backgroundImage: 'none'
        }
        this.fileInputRef = React.createRef();
    }
    componentDidMount() {
        document.title = '订单支付';
        const query = new URLSearchParams(this.props.location.search);
        const order_id = query.get('id');
        if (order_id == null) return this.props.history.replace('/home');
        this.setState({
            order_id
        });
    }
    upload = async () => {
        const { file, order_id } = this.state;
        if (!file) return toast('请先选择支付截图');
        try {
            const ret = await ApiUpload({
                image: file,
                t: 'evidence',
                oss_type: 'local'
            })
            const imgPath = ret.oss_key;
            await ApiPost(DtOrder_uploadPayEvidence, {
                order_id,
                pics: imgPath
            })
            this.setState({
                success: true,
                file: false,
            });
        } catch (e) {
            toast(e);
        }
    };
    selectPic = () => {
        this.fileInputRef.current.click();
    }
    fileChange = e => {
        this.setState({
            file: e.target.files[0],
            backgroundImage: `url(${URL.createObjectURL(e.target.files[0])})`
        });
    };
    reUpload = () => {
        this.setState({
            success: false,
            file: false,
            backgroundImage: 'none'
        });
    };
    render() {
        const { success, file, backgroundImage } = this.state;
        return !success ?
            <div className={style['pay-wrap']}>
                <div className={style['qrcode']}><img src={qrcode} /></div>
                <div className={style['tip1']}>长按扫码付款</div>
                <div className={style['tip2']}>请上传支付截图并点击“提交支付截图”</div>
                { !file ? <div className={style['upload-btn']} onClick={this.selectPic}></div> : <div className={style['upload-pre']} style={{ backgroundImage: backgroundImage }}></div>}
                <input type="file" hidden ref={this.fileInputRef} onChange={this.fileChange} accept="image/*"/>
                {file ? <div className={style['submit-btn']} onClick={this.upload}>提交支付截图</div>:
                <div className={`${style['submit-btn']} ${style['disabled']}`} onClick={this.upload}>提交支付截图</div>}
            </div> :
            <div className={style['success-wrap']}>
                <img className={style['suc-img']} src={sucImg} />
                <div className={style['tip1']}>您已提交支付截图<br/>请等待工作人员审核</div>
                <div className={style['oper-btn']} onClick={this.reUpload}>重新上传</div>
            </div>
    }
}

export default withRouter(Pay);