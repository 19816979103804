import React from 'react';
import './Footer.css';
import home1Img from '../../images/Footer/home1.png';
import home2Img from '../../images/Footer/home2.png';
import cart1Img from '../../images/Footer/cart1.png';
import cart2Img from '../../images/Footer/cart2.png';
import my1Img from '../../images/Footer/my1.png';
import my2Img from '../../images/Footer/my2.png';
import { withRouter } from 'react-router-dom';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectIndex: props.index !== undefined ? props.index : 'home'
        }
    }
    componentDidMount() {

    }
    select = index => {
        this.setState({
            selectIndex: index
        });
        switch (index) {
            case 'home':
                this.props.history.push('/home');
                break;
            case 'cart':
                this.props.history.push('/cart');
                break;
            case 'my':
                this.props.history.push('/my');
                break;
        }
    };
    render() {
        const { selectIndex } = this.state;
        return (
            <div className="footer-nav">
                <div className={selectIndex === 'home' ? 'nav-item selected' : 'nav-item'} onClick={() => this.select('home')}>
                    <img src={selectIndex === 'home' ? home2Img : home1Img} />
                    <span>订货</span>
                </div>
                <div className={selectIndex === 'cart' ? 'nav-item selected' : 'nav-item'} onClick={() => this.select('cart')}>
                    <img src={selectIndex === 'cart' ? cart2Img : cart1Img} />
                    <span>购物车</span>
                </div>
                <div className={selectIndex === 'my' ? 'nav-item selected' : 'nav-item'} onClick={() => this.select('my')}>
                    <img src={selectIndex === 'my' ? my2Img : my1Img} />
                    <span>我的</span>
                </div>
            </div>
        );
    }
}

export default withRouter(Footer);