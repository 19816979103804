const saveLoginInfo = loginInfo => {
    localStorage.setItem('loginInfoSp', JSON.stringify(loginInfo));
};

const getLoginInfo = () => {
    let info = localStorage.getItem('loginInfoSp');
    if (info === null) return false;
    info = JSON.parse(info);
    if (!info) return false;
    if (info.expire * 1000 > Date.now()) {
        return info;
    } else {
        localStorage.removeItem('loginInfoSp');
        return false;
    }

}

const removeLoginInfo = () => {
    localStorage.removeItem('loginInfoSp');
};

export {
    saveLoginInfo,
    getLoginInfo,
    removeLoginInfo
}