import React, { useState } from 'react';
import { getLoginInfo } from '../../UserStore';
import style from './Home.module.css';
import searchImg from '../../images/Home/search.png';
import Footer from '../../components/Footer';
import { withRouter } from 'react-router-dom';
import cartImg from '../../images/Home/cart.png';
import { ApiPost, DtCate_query, DtGoods_info, DtGoods_query } from '../../utils/Api';
import toast from '../../components/Toast';
import { formatMoney } from '../../utils/Tools';
import { add2cart, getCartList, clearCart, minusCartCount, plusCartCount, calcCartAmount, updateCart } from '../../CartStore';
import OrderCart from '../../mobx/OrderCart';
import _ from 'lodash';

function Detail(props) {
    const { info } = props;
    const [selectedSkuId, setSelectedSkuId] = useState(info.sku.length > 0 ? info.sku[0].id : 0);
    const skuItems = info.sku.map((val, index) => {
        if (val.id === selectedSkuId) return <span key={val.id} className={style['selected']}>{val.title}</span>
        return <span key={val.id} onClick={() => setSelectedSkuId(val.id)}>{val.title}</span>
    })
    const selectedSku = _.find(info.sku, { id: selectedSkuId });
    const moneyRange = info.min_price == info.max_price ? formatMoney(info.min_price) : `${formatMoney(info.min_price)}~${formatMoney(info.max_price)}`;
    return (
        <div className={style['detail-mask']}>
            <div class={style['detail-wrap']}>
                <div class={style['main-img']}>
                    <img src={info.main_image} />
                </div>
                <div className={style['title']}>
                    <span>{info.title}</span>
                    <div className={style['price']}><i>￥</i>{selectedSku ? formatMoney(selectedSku.price) : moneyRange}</div>
                </div>
                {info.description && <div className={style['desc']}>{info.description}</div>}
                <div className={style['sku']}>
                    {skuItems}
                </div>
                <div className={style['oper']}>
                    <div className={style['cancel-btn']} onClick={props.onCancel}>取消</div>
                    {selectedSku ? <div className={style['add-btn']} onClick={() => props.onAdd(selectedSku)}>加入购物车</div>:
                    <div className={style['add-btn']} >加入购物车</div>}
                </div>
            </div>
        </div>
    );
}

function NumberBtn(props) {
    return (
        <div className={style['number-btn']}>
            <div className={style['btn']} onClick={props.minus}>-</div>
            <span>{props.number}</span>
            <div className={style['btn']} onClick={props.plus}>+</div>
        </div>
    );
}


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFocus: false,
            selectedCateId: '',
            selectedCateTitle: '所有商品',
            detailModalVisibale: false,
            detailModalInfo: {},
            detailModalId: 0,
            cartShow: false,
            cartMaskShow: false,
            cateList: [],
            wareList: [],
            searchKey: '',
            cartList: getCartList()
        };
        this.searchInputRef = React.createRef();
        this.wareListRef = React.createRef();
    }
    async componentDidMount() {
        document.title = '订货';
        const loginInfo = getLoginInfo();
        if (!loginInfo) {
            return this.props.history.replace('/');
        }
        this.queryWare();
        try {
            const cateList = await ApiPost(DtCate_query);
            this.setState({ cateList });
        } catch (e) {
            toast(e);
        }
    }
    queryWare = async (cate_id = '') => {
        try {
            const wareList = await ApiPost(DtGoods_query, { cate_id });
            this._lazyLoad = true;
            this.setState({ wareList, searchKey: '' });
        } catch (e) {
            toast(e);
        }
    };
    componentDidUpdate(prevProps, prevState) {
        //显示搜索input后给与焦点
        if (prevState.searchFocus === false && this.state.searchFocus === true) {
            this.searchInputRef.current.focus();
        }
        //商品图片懒加载
        if (this._lazyLoad === true) {
            this.lazyLoad();
            this._lazyLoad = false;
        }
    }
    //懒加载逻辑
    lazyLoad = () => {
        const node = this.wareListRef.current;
        node.childNodes.forEach(val => {
            if (val.className.indexOf('empty-ware-list') >= 0) return;
            if (val.offsetTop - node.scrollTop - node.offsetTop < node.offsetHeight) {
                //可视范围内加载图片
                const imgEle = val.getElementsByTagName('img')[0];
                imgEle.src = imgEle.getAttribute('data-src');
            }
        });
    }
    wareListScroll = e => {
        this.lazyLoad();
    }
    searchInputFocus = () => {
        this.setState({
            searchFocus: true
        });
    }
    onSearch = e => {
        const key = e.target.key.value.trim();
        this.setState({ searchKey: key });
        e.preventDefault();
        this._lazyLoad = true;
        this.searchInputRef.current.blur();
    };
    selectCate = (id, title = '所有商品') => {
        this.setState({
            selectedCateId: id,
            selectedCateTitle: title
        });
        this.queryWare(id);
    }
    showDetailModal = async id => {
        try {
            const hide = toast('加载中...', 0);
            let info = await ApiPost(DtGoods_info, { id });
            info.id = id;
            hide();
            this.setState({
                detailModalVisibale: true,
                detailModalInfo: info
            });
        } catch (e) {
            toast(e);
        }
    };
    add2cart = skuInfo => {
        const info = { ...this.state.detailModalInfo, selectedSku: skuInfo, selectedSkuId: skuInfo.id };
        add2cart(info);
        this.setState({
            detailModalVisibale: false,
            cartList: getCartList()
        });
    };
    clearCart = () => {
        clearCart();
        this.setState({
            cartList: []
        });
    }
    hideCart = () => {
        this.setState({
            cartShow: false,
        });
        setTimeout(() => this.setState({ cartMaskShow: false }), 500);
    };
    showCart = () => {
        this.setState({
            cartShow: true,
            cartMaskShow: true
        });
    };
    toggoleChartShow = () => {
        if (this.state.cartShow) {
            this.hideCart();
        } else {
            this.showCart();
        }
    };
    goOrder = e => {
        e.stopPropagation();
        OrderCart.set(getCartList());
        this.props.history.push('/order/confirm');
    };
    cartMinus = ware => {
        minusCartCount(ware);
        this.setState({
            cartList: getCartList()
        });
    };
    cartPlus = ware => {
        plusCartCount(ware);
        this.setState({
            cartList: getCartList()
        });
    };
    render() {
        const { searchFocus, selectedCateId, selectedCateTitle, detailModalVisibale, cartShow, cartMaskShow, cateList, wareList, detailModalInfo, searchKey, cartList } = this.state;
        const navItems = cateList.map(val => {
            if (selectedCateId === val.id) {
                return <div className={style['nav-item'] + ' ' + style['selected']} key={val.id}>{val.title}</div>
            } else {
                return <div className={style['nav-item']} key={val.id} onClick={() => this.selectCate(val.id, val.title)}>{val.title}</div>
            }
        });
        const wareItems = wareList.filter(val => {
            return val.title.indexOf(searchKey) > -1;
        }).map(val => {
            return (
                <div className={style['ware-item']} key={val.id}>
                    <div className={style['picture']}>
                        <img src="" data-src={val.small_image} />
                    </div>
                    <div className={style['info']}>
                        <div className={style['name']}>{val.title}</div>
                        <div className={style['desc']}>{val.description}</div>
                        <div className={style['option']}>
                            <span><i>￥{formatMoney(val.min_price)}</i>/{val.unit}</span>
                            <div className={style['select-btn']} onClick={() => this.showDetailModal(val.id)}>选择</div>
                        </div>
                    </div>
                </div>
            );
        });

        const cartListItems = cartList.map(val => {
            return (
                <div className={style['list-item']} key={val.selectedSkuId}>
                    <div className={style['pic']}><img src={val.small_image} /></div>
                    <div className={style['info']}>
                        <div className={style['title']}>{val.title}</div>
                        <div className={style['sku-title']}>{val.selectedSku.title}</div>
                        <div className={style['price']}>￥{formatMoney(val.selectedSku.price)}<span>/{val.unit}</span></div>
                    </div>
                    <div className={style['oper']}>
                        <NumberBtn number={val.cart_num} plus={() => this.cartPlus(val)} minus={() => this.cartMinus(val)} />
                    </div>
                </div>
            );
        });

        const cartAmount = calcCartAmount();

        return (
            <div className={style['home-wrap']}>
                <div className={style['search-wrap']}>
                    {!searchFocus && searchKey === '' && <div className={style['placeorder']} onClick={this.searchInputFocus}>
                        <img src={searchImg} /><span>搜索</span>
                    </div>}
                    {(searchFocus || searchKey !== '') && <form action="" onSubmit={this.onSearch}>
                        <input name="key" ref={this.searchInputRef} onBlur={() => this.setState({ searchFocus: false })} />
                    </form>}
                </div>
                <div className={style['ware-wrap']}>
                    <div className={style['cate-nav']}>
                        {selectedCateId === '' ? <div className={style['nav-item'] + ' ' + style['selected']}>所有商品</div> :
                            <div className={style['nav-item']} onClick={() => this.selectCate('')}>所有商品</div>}
                        {navItems}
                    </div>
                    <div className={style['ware-list']}>
                        <div className={style['list-top']}>
                            <div className={style['cate-name']}>{selectedCateTitle}</div>
                        </div>
                        <div className={style['list-inner']} ref={this.wareListRef} onScroll={this.wareListScroll}>
                            {wareItems.length > 0 ? wareItems : <div className={style['empty-ware-list']}>暂无商品</div>}
                        </div>
                    </div>
                </div>
                {detailModalVisibale && <Detail onCancel={() => this.setState({ detailModalVisibale: false })} onAdd={this.add2cart} info={detailModalInfo} />}
                <div className={style['cart-bar']} onClick={this.toggoleChartShow}>
                    <div className={style['cart-info']}>
                        <span className={style['count']}>{cartList.length}</span>
                        <img src={cartImg} />
                        <div className={style['price']}>
                            <span>￥</span>{formatMoney(cartAmount)}
                        </div>
                    </div>
                    <div className={cartList.length == 0 ? style['go-order'] : `${style['go-order']} ${style['has']}`} onClick={cartList.length > 0 ? this.goOrder : e => e.stopPropagation()}>去结算</div>
                </div>
                <div className={style['cart-mask']} style={{ visibility: cartMaskShow ? 'visible' : 'hidden' }}>
                    <div className={style['cart-mask-close']} onClick={this.hideCart} />
                    <div className={cartShow ? style['cart-content'] + ' ' + style['show'] : style['cart-content']}>
                        <div className={style['top']}>
                            <div className={style['top-title']}>购物车<span>（共{cartList.length}件商品）</span></div>
                            <div className={style['clear']} onClick={this.clearCart}>清空购物车</div>
                        </div>
                        <div className={style['list-inner']}>
                            {cartListItems.length > 0 ? cartListItems : <div className={style['empty-cart-list']}>暂无商品</div>}
                        </div>
                    </div>
                </div>
                <Footer index="home" />
            </div>
        );
    }
}

export default withRouter(Home);