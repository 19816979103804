import numeral from 'numeral';
import moment from 'moment';
import toast from '../components/Toast';

const formatMoney = money => {
    return numeral(Number(money / 100)).format('0,0.00');
};

function copyText(node) {
    if (!node) {
        return;
    }
    var result;
    // 将复制内容添加到临时textarea元素中
    var tempTextarea = document.createElement('textarea');
    document.body.appendChild(tempTextarea);
    if (typeof (node) == 'object') {
        // 复制节点中内容
        // 是否表单
        if (node.value) {
            tempTextarea.value = node.value;
        } else {
            tempTextarea.value = node.innerHTML;
        }
    } else {
        // 直接复制文本
        tempTextarea.value = node;
    }
    // 判断设备
    var u = navigator.userAgent;
    if (u.match(/(iPhone|iPod|iPad);?/i)) {
        // iOS
        // 移除已选择的元素
        window.getSelection().removeAllRanges();
        // 创建一个Range对象
        var range = document.createRange();
        // 选中
        range.selectNode(tempTextarea);
        // 执行选中元素
        window.getSelection().addRange(range);
        // 复制
        result = document.execCommand('copy');
        // 移除选中元素
        window.getSelection().removeAllRanges();

    } else {
        // 选中    
        tempTextarea.select();
        // 复制
        result = document.execCommand('Copy');
    }
    // 移除临时文本域
    document.body.removeChild(tempTextarea);
    if (result) {
        toast('复制成功', 1000);
    } else {
        toast('复制失败', 1000);
    }

    return result;
}

const formatTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(time * 1000).format(format);
}

export {
    formatMoney,
    copyText,
    formatTime
}