import { makeAutoObservable, values } from "mobx"

class OrderCart {
    cartList = [];

    constructor() {
        makeAutoObservable(this)
    }

    set(newList) {
        this.cartList = newList;
    }
    get() {
        return values(this.cartList);
    }
    clear() {
        this.cartList = [];
    }
    calcCartAmount = () => {
        let amount = 0;
         this.cartList.forEach(val => {
            amount += val.cart_num * val.selectedSku.price
        })
        return amount;
    };
}

export default new OrderCart();