import ReactDOM from 'react-dom';

function ToastModal(props) {
    return (
        <div className="toast-mask">
            <div className="toast-content">{props.msg}</div>
        </div>
    );
};

function toast(msg, life = 1500) {
    const div = document.createElement('div');
    document.body.appendChild(div);
    ReactDOM.render(
        <ToastModal msg={msg} />,
        div
    );
    //0代表永久不消失，要手动关闭
    if (life > 0) {
        setTimeout(() => document.body.removeChild(div), life);
        return () => console.warn('如果要手动关闭toast需要设置life为0');
    }
    return () => document.body.removeChild(div);
}

export default toast;