import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const minWidth = document.documentElement.clientWidth > document.documentElement.clientHeight ? document.documentElement.clientHeight : document.documentElement.clientWidth;
// document.documentElement.style.fontSize = minWidth / 750 * 100 + 'px';
document.body.style.fontSize = 16 / (minWidth / 750)  / 100 + 'rem';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
