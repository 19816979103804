import React from 'react';
import { withRouter, useLocation } from 'react-router';
import { CompatSource } from 'webpack-sources';
import toast from '../../../components/Toast';
import { ApiPost, DtOrder_pickedUp, DtOrder_query } from '../../../utils/Api';
import style from './Order.module.css';
import { formatMoney } from '../../../utils/Tools';

//订单状态( 199:待付款 0:待发货 2: 待收货 1:已完成 空字符串:全部)

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: '待付款',
            list: [],
            page_index: 0,
            page_size: 10,
            loading: false
        }
        this.listRef = React.createRef();
    }
    async componentDidMount() {
        document.title = '我的订单';
        const query = new URLSearchParams(this.props.location.search);
        const t = query.get('t');
        if (['待付款', '待发货', '待收货', '已完成', '全部订单'].indexOf(t) >= 0) {
            this.setState({
                selectedTab: t
            });
        }
        const hide = toast('加载中...', 0);
        await this.loadingList(t);
        hide();
    }
    loadingList = async (selectedTab, init = false) => {
        selectedTab = selectedTab !== undefined ? selectedTab : this.state.selectedTab;
        this.setState({
            loading: true
        });
        const ss = { '待付款': 199, '待发货': 0, '待收货': 2, '已完成': 1, '全部订单': '' };
        const order_status = ss[selectedTab] !== undefined ? ss[selectedTab] : '';
        try {
            let { page_index, page_size, list } = this.state;
            let hide;
            if (init) {
                page_index = 0;
                list = [];
                hide = toast('加载中...', 0);
            }
            const res = await ApiPost(DtOrder_query, {
                page_index: page_index + 1,
                page_size,
                order_status
            });
            if (hide) hide();
            if (res.length > 0) {
                let newArary = [...list, ...res];
                page_index++;
                this.setState({ page_index, list: newArary });
            }
            this.setState({
                loading: false
            });
        } catch (e) {
            toast(e);
        }
    }
    listScroll = (e) => {
        const node = this.listRef.current;
        if (this.state.loading) return;
        if (node.scrollTop + 10 >= node.scrollHeight - node.offsetHeight) {
            this.loadingList();
        }
    };
    tabClick = t => {
        this.props.history.replace('/my/order?t=' + t);
        this.setState({
            selectedTab: t,
            list: [],
        });
        this.loadingList(t, true);
    };
    goPay = order_id => {
        this.props.history.push(`/order/pay?id=${order_id}`);
    };
    pickUp = async id => {
        const hide = toast('操作中...', 0);
        try {
            await ApiPost(DtOrder_pickedUp, { id });
            this.tabClick(this.state.selectedTab);
        } catch (e) {
            toast(e);
        }
        hide();
    }
    render() {
        const { selectedTab, list } = this.state;
        const orderItems = list.map(val => {
            let order_status = '';
            if (val.pay_status === 0) {
                order_status = '待付款';
            }
            if (val.pay_status === 1) {
                order_status = '待发货';
            }
            if (val.order_status === 1) {
                order_status = '已完成';
            } else if (val.order_status === 2) {
                order_status = '待收货';
            }
            let items = JSON.parse(val.item_info);
            items = items.map(item => {
                return (
                    <div className={style['ware-item']} key={`${selectedTab}-${item.item_id}`}>
                        <div className={style['ware-picture']}><img src={item.img} /></div>
                        <div className={style['ware-title']}>
                            {item.title}
                            <span>{item.sku_title}</span>
                        </div>
                        <div className={style['ware-price']}>
                            <div className={style['p']}><span>￥{formatMoney(item.price)}</span></div>
                            <div className={style['count']}>共{item.cnt}件</div>
                        </div>
                    </div>
                );
            })
            return (
                <div className={style['list-item']} key={`${selectedTab}-${val.id}`} onClick={() => {
                    this.props.history.push(`/my/order/detail?id=${val.id}`)
                }}>
                    <div className={style['title']}>
                        订单号:{val.order_no}<span>{order_status}</span>
                    </div>
                    {items}
                    <div className={style['oper']}>
                        <div className={style['total']}>
                            {order_status === '待付款' ? '合计:' : '实付:'}<span>￥{formatMoney(val.total_price)}</span>
                        </div>
                        {order_status === '待付款' && <div className={style['btn']} onClick={(e) => { this.goPay(val.id); e.stopPropagation(); }}>去支付</div>}
                        {order_status === '待收货' && <div className={style['btn']} onClick={(e) => { this.pickUp(val.id); e.stopPropagation(); }}>确认收货</div>}
                    </div>
                </div>
            );
        });
        return (
            <div className={style['order-wrap']}>
                <div className={style['tab-bar']}>
                    {selectedTab === '待付款' ?
                        <div className={style['tab-item'] + ' ' + style['selected']}>待付款<div className={style['tab-item-decoration']}></div></div> :
                        <div className={style['tab-item']} onClick={() => this.tabClick('待付款')}>待付款</div>}
                    {selectedTab === '待发货' ?
                        <div className={style['tab-item'] + ' ' + style['selected']}>待发货<div className={style['tab-item-decoration']}></div></div> :
                        <div className={style['tab-item']} onClick={() => this.tabClick('待发货')}>待发货</div>}
                    {selectedTab === '待收货' ?
                        <div className={style['tab-item'] + ' ' + style['selected']}>待收货<div className={style['tab-item-decoration']}></div></div> :
                        <div className={style['tab-item']} onClick={() => this.tabClick('待收货')}>待收货</div>}
                    {selectedTab === '已完成' ?
                        <div className={style['tab-item'] + ' ' + style['selected']}>已完成<div className={style['tab-item-decoration']}></div></div> :
                        <div className={style['tab-item']} onClick={() => this.tabClick('已完成')}>已完成</div>}
                    {selectedTab === '全部订单' ?
                        <div className={style['tab-item'] + ' ' + style['selected']}>全部订单<div className={style['tab-item-decoration']}></div></div> :
                        <div className={style['tab-item']} onClick={() => this.tabClick('全部订单')}>全部订单</div>}
                </div>
                <div className={style['list-inner']} onScroll={this.listScroll} ref={this.listRef}>
                    {orderItems.length > 0 ? orderItems : <div className={style['list-empty']}>无任何订单</div>}
                </div>
            </div>
        );
    }
}

export default withRouter(Order);