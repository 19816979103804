import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Home from './pages/Home';
import ConfirmOrder from './pages/Order/Confirm';
import PayOrder from './pages/Order/Pay';
import PaySuccess from './pages/Order/Success';
import My from './pages/My/Home';
import MyOrder from './pages/My/Order';
import Cart from './pages/Cart';
import OrderDetail from './pages/My/Order/Detail';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/order/confirm">
            <ConfirmOrder />
          </Route>
          <Route path="/order/pay">
            <PayOrder />
          </Route>
          <Route path="/order/success">
            <PaySuccess />
          </Route>
          <Route exact path="/my">
            <My />
          </Route>
          <Route exact path="/my/order">
            <MyOrder />
          </Route>
          <Route path="/my/order/detail">
            <OrderDetail />
          </Route>
          <Route  path="/cart">
            <Cart />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
