import React from 'react';
import style from './Confirm.module.css';
import { withRouter } from 'react-router-dom';
import { getLoginInfo } from '../../../UserStore';
import { formatMoney } from '../../../utils/Tools';
import OrderCart from '../../../mobx/OrderCart';
import toast from '../../../components/Toast';
import { ApiPost, DtOrder_create } from '../../../utils/Api';
import { remove2cart } from '../../../CartStore';

class ConfirmOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartList: [],
            addressInfo: {
                mobile: '',
                address: '',
                name: '',
                address_id: 0,
            },
            totalAmount: 0
        };
    }
    componentDidMount() {
        document.title = '确认订单';
        const cartList = OrderCart.get();
        if (cartList.length === 0) {
            return this.props.history.replace('/home');
        }
        const loginInfo = getLoginInfo();
        if (loginInfo === false) return this.props.history.replace('/');
        this.setState({
            cartList,
            addressInfo: {
                mobile: loginInfo.addr_mobile,
                address: loginInfo.address,
                name: loginInfo.addr_name,
                address_id: loginInfo.address_id
            },
            totalAmount: OrderCart.calcCartAmount()
        });
        OrderCart.clear();
    }
    pay = async () => {
        const { addressInfo, cartList } = this.state;
        const hide = toast('加载中', 0);
        let item_ids = [];
        let item_counts =  [];
        cartList.forEach(val => {
            item_ids.push(val.selectedSku.id);
            item_counts.push(val.cart_num);
        })
        try {
            const ret = await ApiPost(DtOrder_create, {
                note: '',
                item_ids: item_ids.join(','),
                item_counts: item_counts.join(','),
                address_id: addressInfo.address_id
            })
            if (ret.pay_status === 1) {
                //直接支付成功
                this.props.history.replace('/order/success');
            } else {
                //支付页面
                this.props.history.replace(`/order/pay?id=${ret.order_id}`)
            }
            //从购物车清除已下单商品
            cartList.forEach(val => {
                remove2cart(val);
            })
            OrderCart.clear();
        } catch (e) {
            toast(e);
        }
        hide();
    }
    render() {
        const { addressInfo, cartList, totalAmount } = this.state;

        const cartListItems = cartList.map(val => {
            return (
                <div className={style['list-item']} key={val.selectedSkuId}>
                    <div className={style['pic']}><img src={val.small_image} /></div>
                    <div className={style['info']}>
                        <div className={style['title']}>{val.title}</div>
                        <div className={style['sku-title']}>{val.selectedSku.title}</div>
                        <div className={style['price']}>￥{formatMoney(val.selectedSku.price)}<span>/{val.unit}</span></div>
                    </div>
                    <div className={style['count']}>共{val.cart_num}件</div>
                </div>
            );
        });
        return (
            <div className={style['confirm-wrap']}>
                <div className={style['confirm-wrap-inner']}>
                    <div className={style['address-wrap']}>
                        <p>{addressInfo.address}</p>
                        <span>{addressInfo.name} {addressInfo.mobile}</span>
                    </div>
                    <div className={style['detail-wrap']}>
                        <div className={style['detail-title']}>
                            <span>订单详情</span>
                            <span>共{cartList.length}件</span>
                        </div>
                        <div className={style['cart-content']}>
                            {cartListItems}
                        </div>
                    </div>
                    <div className={style['order-amount']}>
                        <span>商品总额</span>
                        <span>￥{formatMoney(totalAmount)}</span>
                    </div>
                </div>
                <div className={style['order-bar']}>
                    <div className={style['info']}>
                        待支付：<span>￥{formatMoney(totalAmount)}</span>
                    </div>
                    <div className={style['pay-btn']} onClick={this.pay}>立即支付</div>
                </div>
            </div>
        );
    }
}

export default withRouter(ConfirmOrder);