import React from 'react';
import style from './Detail.module.css';
import { formatMoney, copyText, formatTime } from '../../../../utils/Tools';
import { ApiPost, DtOrder_info, DtOrder_pickedUp } from '../../../../utils/Api';
import toast from '../../../../components/Toast';
import { withRouter } from 'react-router-dom';

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: false
        }
    }
    async componentDidMount() {
        document.title = '订单详情';
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id');
        if (id === null) return window.history.go(-1);
        const hide = toast('加载中...', 0);
        await this.loadDetail(id);
        hide();
    }
    loadDetail = async (id) => {
        try {
            const detail = await ApiPost(DtOrder_info, { id });
            this.setState({
                detail
            });
        } catch (e) {
            toast(e);
        }
    }
    goPay = order_id => {
        this.props.history.push(`/order/pay?id=${order_id}`);
    };
    pickUp = async id => {
        const hide = toast('操作中...', 0);
        try {
            await ApiPost(DtOrder_pickedUp, { id });
            await this.loadDetail(id);
        } catch (e) {
            toast(e);
        }
        hide();
    }
    render() {
        const { detail } = this.state;
        if (!detail) return <div className={style['detail-wrap']}></div>
        let order_status = '-';
        if (detail.pay_status === 0) {
            order_status = '待付款';
        }
        if (detail.pay_status === 1) {
            order_status = '待发货';
        }
        if (detail.order_status === 1) {
            order_status = '已完成';
        } else if (detail.order_status === 2) {
            order_status = '待收货';
        }
        let items = JSON.parse(detail.item_info);
        items = items.map(item => {
            return (
                <div className={style['ware-item']} key={item.item_id}>
                    <div className={style['ware-picture']}><img src={item.img} /></div>
                    <div className={style['ware-title']}>
                        {item.title}
                        <span>{item.sku_title}</span>
                    </div>
                    <div className={style['ware-price']}>
                        <div className={style['p']}><span>￥{formatMoney(item.price)}</span></div>
                        <div className={style['count']}>共{item.cnt}件</div>
                    </div>
                </div>
            );
        })
        return (
            <div className={style['detail-wrap']}>
                <div className={style['top-wrap']}>{order_status}</div>
                <div className={style['order-item']}>
                    <div className={style['top-title']}>订货商品</div>
                    {items}
                    <div className={style['oper']}>
                        <div className={style['total']}>
                            {order_status === '待付款' ? '合计:' : '实付:'}<span>￥{formatMoney(detail.total_price)}</span>
                        </div>
                    </div>
                </div>
                <div className={style['order-info']}>
                    <div className={style['top-title']}>订单信息</div>
                    <div className={style['info-item']}>
                        <div className={style['label']}>订单编号</div>
                        <div className={style['content']}>{detail.order_no}<div className={style['copy']} onClick={() => copyText(detail.order_no)}>复制</div></div>
                    </div>
                    <div className={style['info-item']}>
                        <div className={style['label']}>下单时间</div>
                        <div className={style['content']}>{formatTime(detail.create_time)}</div>
                    </div>
                    <div className={style['info-item']}>
                        <div className={style['label']}>支付方式</div>
                        <div className={style['content']}>微信支付</div>
                    </div>
                </div>
                {order_status == '待付款' && <div className={style['oper-btn']} onClick={() => this.goPay(detail.id)}>去付款</div>}
                {order_status == '待收货' && <div className={style['oper-btn']} onClick={() => this.pickedUp(detail.id)}>确认收货</div>}
            </div>
        );
    }
}

export default withRouter(Detail);