import axios from 'axios';
import toast from '../components/Toast';
import { getLoginInfo } from '../UserStore';
const APP_API_URL = 'http://order.h.itboye.com/base';
// const APP_API_URL = 'http://api.wd.itboye.com/base';

export function ApiUpload (params, onlyData = true) {
    const loginInfo = getLoginInfo();
    return new Promise(async (resolve, reject) => {
        if (loginInfo === false) {
            setTimeout(() => {
                window.location = '/';
            }, 500);
            reject('请先登录');
            return;
        }
        try {
            let headers = { 'content-type': 'multipart/form-data' };
            let formData  = new FormData();
            const data =  {
               uid: loginInfo.id,
                ...params            
            };
            for (let p in data) {
                formData.append(p, data[p]);
            }
            const response = await axios({
                method: 'POST',
                headers: headers,
                data: formData,
                url: Picture_upload
            });
            if (response.status == 200) {
                if (response.data.code === 0) {
                    if (onlyData) {
                        resolve(response.data.data);
                    } else {
                        resolve(response.data);
                    }
                } else {
                    if (response.data.code === 1111) {
                        setTimeout(() => {
                            window.location = '/';
                        }, 500);
                    }
                    reject(response.data.msg);
                }
            } else {
                reject('请求失败');
            }
        } catch(e) {
            reject('请求失败');
        }
    });
}


export function ApiPost (url, params, onlyData = true) {
    url = APP_API_URL + url;
    let formData  = new FormData();
    const data =  {
        app_type: 'web',
        app_version: '1.0.0',
        client_id: 'by04esgV0j28Uv',
        notify_id: parseInt(Math.random()*100000),
        app_request_time: parseInt(Date.now() / 1000),
        lang: 'zh-cn',
        ...params            
    };
    for (let p in data) {
        formData.append(p, data[p]);
    }

    return new Promise(async (resolve, reject) => {
        try {
            let headers = { 'content-type': 'multipart/form-data' };
            const loginInfo = getLoginInfo();
            if (loginInfo) headers['Authorization'] = `Bearer ${loginInfo.jwt}`;
            const response = await axios({
                method: 'POST',
                headers: headers,
                data: formData,
                url
            });
            if (response.status == 200) {
                if (response.data.code === 0) {
                    if (onlyData) {
                        resolve(response.data.data);
                    } else {
                        resolve(response.data);
                    }
                } else {
                    if (response.data.code === 1111) {
                        setTimeout(() => {
                            window.location = '/';
                        }, 500);
                    }
                    reject(response.data.msg);
                }
            } else {
                reject('请求失败');
            }
        } catch(e) {
            reject('请求失败');
        }
    });
};

// export async function getConfig () {
//     let config = localStorage.getItem('config');
//     if (config !== null) {
//         config = JSON.parse(config);
//         if (config.time + 5 * 60 * 1000 > Date.now()) return config.data;
//     }
//     try {
//         const res = await ApiPost(UcSys_index);
//         localStorage.setItem('config', JSON.stringify({
//             data: res,
//             time: Date.now()
//         }));
//         return res;
//     } catch (e) {
//         toast(e);
//     }
// }

//图片上传接口
export const Picture_upload = 'http://imgorder.h.itboye.com/v2/upload';
// export const Picture_upload = 'http://imgapi.wd.itboye.com/v2/upload';

//[登录]手机号+密码
export const UserLoginSession_loginByMobilePassword = '/100/UserLoginSession/loginByMobilePassword';

//分类查询接口
export const DtCate_query = '/100/DtCate/query';

//商品查询接口(按分类,全部查询按displayOrder从大到小)
export const DtGoods_query = '/100/DtGoods/query';

//商品详情接口
export const DtGoods_info = '/100/DtGoods/info';

//订单创建接口
export const DtOrder_create = '/100/DtOrder/create';

//订单查询接口
export const DtOrder_query = '/100/DtOrder/query';

//上传支付凭证接口
export const DtOrder_uploadPayEvidence = '/100/DtOrder/uploadPayEvidence';

//订单详情接口
export const DtOrder_info = '/100/DtOrder/info';

//订单收货接口
export const DtOrder_pickedUp = '/100/DtOrder/pickedUp';